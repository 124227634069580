import { Link } from "react-router-dom";

function Home() {
   
	return (
		<div className="container">
			<ul>
                <li>
                    <Link to="https://yahoosearch.dev.mystart.com/wizefind">wizefind</Link>
                </li>
                <li>
                    <Link to="https://yahoosearch.dev.mystart.com/lifebalancedaily">lifebalancedaily</Link>
                </li>
                <li>
                    <Link to="https://yahoosearch.dev.mystart.com/aboutzenlife">aboutzenlife</Link>
                </li>
            </ul>
		</div>
	);
}

export default Home;
