import React from "react";
import Listing from './Pages/Listing';
import Home from "./Pages/Home";

import { BrowserRouter as Router, Route, Routes} from "react-router-dom";

function App() {
	return ( 
        <Router>
			<Routes>
				<Route path = "/" element={<Home/>} />
				<Route path = "/:id" element={<Listing/>} />
			</Routes> 
        </Router>
    );
}

export default App;
