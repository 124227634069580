import React, { useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import Profiles from "../components/Profiles";


function Listing() {
    const { id } = useParams();
    
	const [list, setList] = useState([])
	const inputRef = useRef(null);
	const handleClick = (e) => {
		e.preventDefault()
		const keyword = inputRef.current.value;
		const partner = Profiles[id]['partner'];
		const site = Profiles[id]['site'];

		const endpoint = 'https://yahoosearch.dev.mystart.com/api/index.php';
		fetch(endpoint, {
			"method": "POST",
			"body": JSON.stringify({keyword : keyword, partner : partner, site : site})
		})                        
		.then(response => response.json())
		.then(response => setList(response))
	}
	
	return (
		<div className="container">
			<form onSubmit={(e) => handleClick(e)}>
				<div className="form-outline">
					<input type="search" id="form1" className="form-control" placeholder="Type query" aria-label="Search" ref={inputRef}/>
				</div>
			</form>
			<div style={{display:'flex', flexDirection:'column'}}>
			{
			list.map((item, i) => (
				<div className="resultItem">
					<a href={item.clickUrl} style={{textDecoration:'none'}}>
					<div className="sitehost">{item.siteHost}</div>
					<div className="title">{decodeURIComponent(item.title)}</div>
					<div className="description">{item.description}</div>
					</a>
				</div>
			))
			}
			</div>
		</div>
	);
}

export default Listing;
