const Profiles = {
    "wizefind" : {
        partner : "wizefind",
        site : 'https://wizefind.com'
    },
    "lifebalancedaily" : {
        partner : "lifebalancedaily",
        site : 'https://lifebalancedaily.com'
    },
    "aboutzenlife" : {
        partner : "aboutzenlife",
        site : 'https://aboutzenlife.com'
    },
};
export default Profiles;